<script setup>
import {ref, computed} from 'vue';
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';


import { useRoute } from 'vue-router';
const route = useRoute();

import { storeToRefs } from 'pinia';

import { usePlusSurveyStore } from '../store/plusSurveyStore';
const plusSurveyStore = usePlusSurveyStore();
const { subjects, sites, plusExamSurvey} = storeToRefs(plusSurveyStore);
const { fetchPlusExamSurvey } = plusSurveyStore;

const currentSubject = ref(null);
const curSite = ref(null);
const userSubtopicVotes = ref(null);
const userAddedSubtopic = ref(null);
const userComments = ref(null)

// View Votes Modal
const isViewVotesModalOpen = ref(false);
const closeViewVotesModal = () => {
    isViewVotesModalOpen.value = false;
    userSubtopicVotes.value = null;
    userAddedSubtopic.value = null;
}
const openViewVotesModal = (survey) => {
    
    switch (currentSubject.value.id) {
        case 'identification-general':
            userSubtopicVotes.value = survey.data.identificationGeneralTopics;
            userAddedSubtopic.value = survey.data.identificationGeneralTopicsAdded;
            break;
        case 'identification-aircraft':
            userSubtopicVotes.value = survey.data.identificationAircraftTopics;
            userAddedSubtopic.value = survey.data.identificationAircraftTopicsAdded;
            break;
            case 'hands-on-performance':
            userSubtopicVotes.value = survey.data.handsOnPerformanceTopics;
            userAddedSubtopic.value = survey.data.handsOnPerformanceTopicsAdded;
            break;
            case 'explanation-of-process':
            userSubtopicVotes.value = survey.data.explanationOfProcessTopics;
            userAddedSubtopic.value = survey.data.explanationOfProcessTopicsAdded;
            break;
    }
    
    isViewVotesModalOpen.value = true;
}

// View Comments Modal
const isViewCommentsModalOpen = ref(false);
const closeViewCommentsModal = () => {
    isViewCommentsModalOpen.value = false;
    userComments.value = null;
}
const openViewCommentsModal = (survey) => {
    userComments.value = survey.data.comments;
    isViewCommentsModalOpen.value = true;
}

const filteredResponses = computed(() => {
    if (curSite.value !== null) {
        let _res = plusExamSurvey.value.filter((res => res.data.site == curSite.value.id))
        return _res;

    }else{
        return plusExamSurvey.value;
    }
})

const newSubtopics = computed(()=>{
    let _newSubtopics = [];

    switch (currentSubject.value.id) {
        case 'identification-general':
            filteredResponses.value.forEach(survey =>{
                _newSubtopics.push(...survey.data.identificationGeneralTopicsAdded);
            })
            break;
        case 'identification-aircraft':
            filteredResponses.value.forEach(survey =>{
                _newSubtopics.push(...survey.data.identificationAircraftTopicsAdded);
            })
            break;
        case 'hands-on-performance':
            filteredResponses.value.forEach(survey =>{
                _newSubtopics.push(...survey.data.handsOnPerformanceTopicsAdded);
            })
            break;
        case 'explanation-of-process':
            filteredResponses.value.forEach(survey =>{
                _newSubtopics.push(...survey.data.explanationOfProcessTopicsAdded);
            })
            break;
    }

    return _newSubtopics;

})

const subtopicVote = (subtopicId)=>{

    let count = 0;

    switch (currentSubject.value.id) {
        case 'identification-general':
            count = filteredResponses.value.filter(user => user.data.identificationGeneralTopics.includes(subtopicId)).length;
            break;
        case 'identification-aircraft':
            count = filteredResponses.value.filter(user => user.data.identificationAircraftTopics.includes(subtopicId)).length;
            break;
        case 'hands-on-performance':
            count = filteredResponses.value.filter(user => user.data.handsOnPerformanceTopics.includes(subtopicId)).length;
            break;
        case 'explanation-of-process':
            count = filteredResponses.value.filter(user => user.data.explanationOfProcessTopics.includes(subtopicId)).length;
            break;
    }

    return count;

}

const siteName = (siteId)=>{
    let _site = sites.value.find(site => site.id == siteId);
    return _site ? _site.name : '---';
}

const getSubtopicName = (subtopicId) => {
    let subtopic = currentSubject.value.topics.find(topic => topic.id == subtopicId)
    return subtopic.name;
}

const init = async () => {
    currentSubject.value = subjects.value[route.params.subjectId];
    await fetchPlusExamSurvey();
}

init();

</script>
<template>
    <div class=" flex justify-center text-warm-gray-300">
        
        <div class=" max-w-3xl mt-8 w-full">

            <div class=" flex justify-between items-center">
                <h1 class=" text-xl font-semibold text-warm-gray-100">{{ currentSubject.name }}</h1>
                <router-link to="/plus-survey" class=" text-warm-gray-500 justify-center items-center flex gap-1">
                    <ArrowLeftIcon class="w-4 h-4"/>
                    Go Back
                </router-link>
            </div>


            <div class="mt-8">
                <p class=" mt-4">Site:</p>
                <div class=" flex justify-start items-center mt-2">
                    <select v-model="curSite" name="site" id="site" class=" rounded-lg border border-warm-gray-800 bg-warm-gray-700 focus:ring-0 focus:outline-none focus:border-yellow-700">
                        <option :value="null">All</option>
                        <option v-for="site in sites" :value="site">{{ site.name }}</option>
                    </select>
                </div>
            </div>

            <!-- Subtopic Votes -->
            <div class="mt-8 ">
                <div class=" flex justify-between items-center">
                    <h1 class=" font-semibold text-lg">Subtopic Votes</h1>
                </div>
                <div class=" rounded-t-lg overflow-hidden mt-2">
                    <table class="w-full">
                        <tr class=" bg-warm-gray-700">
                            <th class=" text-left p-2">Topic Name</th>
                            <th class=" text-center p-2">Votes</th>
                        </tr>
                        <tr v-for="topic in currentSubject.topics" class="border-b border-dashed border-warm-gray-600 text-warm-gray-500">
                            <td class=" text-left p-2">{{ topic.name }}</td>
                            <td class=" text-center p-2">{{ subtopicVote(topic.id) }}</td>
                        </tr>
                       
                    </table>
                </div>
            </div>

            <!-- New Subtopics -->
            <div class="mt-8 ">
                <div class=" flex justify-between items-center">
                    <h1 class=" font-semibold text-lg">New Subtopics</h1>
                </div>
                <div class="rounded-t-lg overflow-hidden mt-2">
                    <table class="w-full">
                        <tr class=" bg-warm-gray-700">
                            <th class=" text-left p-2">Topic Name</th>
                        </tr>
                        <tr v-for="topic in newSubtopics" class="border-b border-dashed border-warm-gray-600 text-warm-gray-500">
                            <td class=" text-left p-2">{{ topic }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- Responses -->
            <div class="mt-8 ">
                <div class=" flex justify-between items-center">
                    <h1 class=" font-semibold text-lg">Responses</h1>
                </div>
                <div class="rounded-t-lg overflow-hidden mt-2">
                    <table class="w-full">
                        <tr class=" bg-warm-gray-700">
                            <th class=" text-left p-2">Users</th>
                            <th class=" text-center p-2">Test Site</th>
                            <th class=" text-center p-2">Date Taken</th>
                            <th class=" text-center p-2"></th>
                        </tr>
                        <tr v-for="response in filteredResponses" class="border-b border-dashed border-warm-gray-600 text-warm-gray-500">
                            <td class=" text-left p-2">
                                <router-link :to="`/plus-survey/${currentSubject.id}/user/${response.data.userId}`" class=" underline">
                                    {{ response.data.userEmail }}
                                </router-link>
                            </td>
                            <td class=" text-center p-2">{{ siteName(response.data.site) }} {{ response.data.siteSpecificName ? ' (' + response.data.siteSpecificName + ')' : ''  }}</td>
                            <td class=" text-center p-2">Oct. 1, 2024</td>
                            <td class=" flex justify-center items-center gap-2 p-2">
                                <button @click="openViewVotesModal(response)" class=" text-yellow-600 hover:underline">Votes</button>
                                <button @click="openViewCommentsModal(response)" class=" text-yellow-600 hover:underline">Comments</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>


        <!-- Votes View Modal -->
        <TransitionRoot appear :show="isViewVotesModalOpen" as="template">
            <Dialog as="div" @close="closeViewVotesModal">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">

                <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

                <span class="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>

                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                    >
                    <div
                    class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
                    >

                    <div class="flex-row items-start p-6">

                        <div class="border-b border-warm-gray-600 p-2">
                        <h3 class="text-warm-gray-900  dark:text-warm-gray-100 font-bold text-base">Subtopic Votes</h3>
                        </div>

                        <div v-if="userSubtopicVotes !== null" class=" p-2">

                            <ul class=" mt-4">
                                <li v-for="subtopic in userSubtopicVotes" class=" p-2 rounded bg-warm-gray-600 mb-2 text-warm-gray-400">{{ getSubtopicName(subtopic) }}</li>
                            </ul>

                            <p class=" mt-8 text-warm-gray-300">New subtopic added</p>
                            <ul class=" mt-4">
                                <li v-for="subtopic in userAddedSubtopic" class=" p-2 rounded bg-warm-gray-600 mb-2 text-warm-gray-400">{{ subtopic }}</li>
                            </ul>

                        </div>

                    </div>

                    <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                        <div>
                            <!-- button here -->
                        </div>
                        <div>
                            <button
                                type="button"
                                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                                @click="closeViewVotesModal"
                                >
                                Close
                            </button>
                        </div>
                    </div>
                    </div>
                </TransitionChild>
                </div>
            </div>
            </Dialog>
        </TransitionRoot>

        <!-- Comments View Modal -->
        <TransitionRoot appear :show="isViewCommentsModalOpen" as="template">
            <Dialog as="div" @close="closeViewCommentsModal">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">

                <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

                <span class="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>

                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                    >
                    <div
                    class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
                    >

                    <div class="flex-row items-start p-6">

                        <div class="border-b border-warm-gray-600 p-2">
                            <h3 class="text-warm-gray-900  dark:text-warm-gray-100 font-bold text-base">User Comments</h3>
                        </div>

                        <div v-if="userComments !== null" class=" p-2">
                           
                            <div class=" text-warm-gray-400 p-4">
                                {{ userComments }}
                            </div>
                        </div>

                    </div>

                    <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                        <div>
                            <!-- button here -->
                        </div>
                        <div>
                            <button
                                type="button"
                                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                                @click="closeViewCommentsModal"
                                >
                                Close
                            </button>
                        </div>
                    </div>
                    </div>
                </TransitionChild>
                </div>
            </div>
            </Dialog>
        </TransitionRoot>

    </div>
</template>